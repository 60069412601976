import React, { useState } from 'react';

import useApi from '../../../../hooks/useApi';

import DatePicker from '../../../general/input/DatePicker';
import FileUpload from '../../../general/input/FileUpload';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/certifications/NewEmployeeCertificationModal.module.scss';

const NewEmployeeCertificationModal = ({
    blocking,
    handleClose = () => null,
    onCreated = () => null
}) => {
    const [employees, setEmployees] = useState([]);
    const [employeeError, setEmployeeError] = useState(null);
    const [certification, setCertification] = useState([]);
    const [expiresDate, setExpiresDate] = useState(null);
    const [expiresError, setExpiresError] = useState(null);
    const [receivedDate, setReceivedDate] = useState(null);
    const [receivedError, setReceivedError] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    //Create an employee certification
    const [{ loading: creationLoading }, createCertification] = useApi(
        '/employee-certifications',
        'POST',
        { manual: true }
    );

    const handleEmployeeChange = (employees) => setEmployees(employees);
    const handleCertificationChange = (certifications) =>
        setCertification(certifications);
    const handleReceiveChange = (receive) => setReceivedDate(receive);
    const handleExpireChange = (expire) => setExpiresDate(expire);

    const handleUploadAttachment = (files) => setAttachments(files);

    const resetErrors = () => {
        setEmployeeError(null);
        setReceivedError(null);
        setExpiresError(null);
        setAttachmentError(null);
    };

    const handleCreateCertification = () => {
        resetErrors();
        let formData = new FormData();

        if (!employees || employees?.length === 0) {
            return setEmployeeError('You must select at least 1 employee.');
        }

        if (!receivedDate) {
            return setReceivedError('You must provide a received date.');
        }

        if (receivedDate <= new Date('1970-01-01T00:00:00')) {
            return setReceivedError(
                'Please provide a 4 digit year after 1970.'
            );
        }

        if (expiresDate && new Date(receivedDate) > new Date(expiresDate)) {
            return setExpiresError('Expires date must be after Received date.');
        }

        if (!attachments || attachments?.length === 0) {
            return setAttachmentError('You must include an attachment.');
        }

        formData.append(
            'jsonData',
            JSON.stringify({
                employeeIds: employees.map((employee) => employee.id),
                expiresOn: expiresDate
                    ? DateTime.fromJSDate(new Date(expiresDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certifiedOn: receivedDate
                    ? DateTime.fromJSDate(new Date(receivedDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certificationTypeId: certification?.[0]?.id
            })
        );

        attachments.forEach((attachment) =>
            formData.append(attachment.filename, attachment.file)
        );

        createCertification({
            data: formData
        })
            .then((cert) => {
                onCreated(cert);
                handleClose();
                enqueueSnackbar('Employee certification sent for review.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    err?.response?.data?.detail ?? 'Error encountered while submitting your certification.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    return (
        <Modal
            open={true}
            blocking={blocking}
            handleClose={handleClose}
            style={{
                content: {
                    minHeight: '477px'
                }
            }}
        >
            <Modal.Title>New Employee Certification</Modal.Title>
            <Modal.Body>
                <div className={styles.container}>
                    <div className={styles.section}>
                        <div className={styles.detailGroup}>
                            <div
                                className={[
                                    styles.detailProperty,
                                    styles.flexGrow
                                ].join(' ')}
                                style={{ maxWidth: '309px' }}
                            >
                                <InputLabel
                                    label="Employee"
                                    required={true}
                                    error={employeeError}
                                >
                                    <Select
                                        handleRowSelection={
                                            handleEmployeeChange
                                        }
                                        multiselect
                                        selected={employees}
                                        getRowValue={(row) =>
                                            row?.firstName || row?.lastName
                                                ? `${row?.lastName}, ${
                                                      row?.firstName
                                                  } ${row?.middleName ?? ''}`
                                                : undefined
                                        }
                                        getRowId={(row) => row.id}
                                        pagination={{
                                            url: '/employees'
                                        }}
                                        sort={[
                                            'LastName',
                                            'FirstName',
                                            'MiddleName',
                                            'Suffix',
                                            'EmployeeNumber'
                                        ]}
                                        placeholder="Employee Name(s)"
                                    />
                                </InputLabel>
                            </div>
                            <div className={styles.detailProperty}>
                                <InputLabel
                                    label="Received"
                                    disclaimer="ex: 09/12/2021"
                                    required={true}
                                    error={receivedError}
                                >
                                    <DatePicker
                                        value={receivedDate}
                                        onChange={handleReceiveChange}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                        <div className={styles.detailGroup}>
                            <div
                                className={[
                                    styles.detailProperty,
                                    styles.flexGrow
                                ].join(' ')}
                                style={{ maxWidth: '309px' }}
                            >
                                <InputLabel label="Certification">
                                    <Select
                                        handleRowSelection={
                                            handleCertificationChange
                                        }
                                        selected={certification}
                                        getRowValue={(row) =>
                                            row?.code || row?.description
                                                ? `${row?.code} - ${row?.description}`
                                                : undefined
                                        }
                                        getRowNodeId={(row) => row.id}
                                        pagination={{
                                            url: '/certification-types'
                                        }}
                                        sort={['Code', 'Description']}
                                        placeholder="Certification Name"
                                    />
                                </InputLabel>
                            </div>
                            <div className={styles.detailProperty}>
                                <InputLabel
                                    label="Expires"
                                    error={expiresError}
                                >
                                    <DatePicker
                                        value={expiresDate}
                                        onChange={handleExpireChange}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.detailGroup}>
                            <div className={styles.detailProperty}>
                                <InputLabel
                                    label="Attachments"
                                    required={true}
                                    error={attachmentError}
                                    labelClick={false}
                                >
                                    <FileUpload
                                        handleChange={handleUploadAttachment}
                                        files={attachments}
                                    />
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Create',
                        loading: creationLoading,
                        onClick: handleCreateCertification,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

NewEmployeeCertificationModal.propTypes = {
    blocking: PropTypes.bool,
    handleClose: PropTypes.func,
    onCreated: PropTypes.func
};

export default NewEmployeeCertificationModal;
