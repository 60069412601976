import { useState } from 'react';

import Button from '../../input/Button';
import CheckboxGroup from '../../input/CheckboxGroup';
import TextInput from '../../input/TextInput';
import Modal from '../../modal/Modal';

const AddBranchCircuitPanel = ({ handleClose, handleSave }) => {
    const [leftItems, setLeftItems] = useState(
        Array.from({ length: 21 }, (_) => '')
    );
    const [rightItems, setRightItems] = useState(
        Array.from({ length: 21 }, (_) => '')
    );
    const [panelCount, setPanelCount] = useState('one');

    const handleChange = (group, index, e) => {
        let func = group === 'left' ? setLeftItems : setRightItems;

        func((items) =>
            items.map((_, itemIndex) => {
                if (index === itemIndex) return e.target.value;
                else return _;
            })
        );
    };

    const handlePaste = (group, index, event) => {
        let pastedRows = event.clipboardData.getData('text');
        if (!pastedRows) return;
        let pastedItems = pastedRows.replaceAll('\r', '').split('\n');

        if (group === 'left') {
            setLeftItems((items) =>
                items.map((_, itemIndex) => {
                    if (
                        itemIndex >= index &&
                        itemIndex - index <= pastedItems.length
                    ) {
                        return pastedItems[itemIndex - index];
                    }
                    return _;
                })
            );
        } else if (group === 'right') {
            setRightItems((items) =>
                items.map((_, itemIndex) => {
                    if (
                        itemIndex >= index &&
                        itemIndex - index <= pastedItems.length
                    ) {
                        return pastedItems[itemIndex - index];
                    }
                    return _;
                })
            );
        }

        event.preventDefault();
        event.stopPropagation();
    };

    const handleAddPanel = () => {
        let finalPanels = [];
        let firstPanel = [];
        for (let i = 0; i < 21; i++) {
            firstPanel = [...firstPanel, leftItems[i], rightItems[i]];
        }
        finalPanels = [...finalPanels, firstPanel];
        if (panelCount === 'two') {
            let secondPanel = [];
            for (let i = 21; i < 42; i++) {
                secondPanel = [...secondPanel, leftItems[i], rightItems[i]];
            }
            finalPanels = [...finalPanels, secondPanel];
        }
        handleSave(finalPanels);
        handleClose();
    };

    const handleChangePanelCount = (value) => {
        if (value === 'one') {
            setLeftItems((items) => items.slice(0, 21));
            setRightItems((items) => items.slice(0, 21));
            setPanelCount('one');
        } else if (value === 'two') {
            setLeftItems((items) => [
                ...items,
                ...Array.from({ length: 21 }, (_) => '')
            ]);
            setRightItems((items) => [
                ...items,
                ...Array.from({ length: 21 }, (_) => '')
            ]);
            setPanelCount('two');
        }
    };

    return (
        <Modal open handleClose={handleClose}>
            <Modal.Title>Add New Panel</Modal.Title>
            <Modal.Body>
                <div className='mb-4'>
                    <CheckboxGroup
                        options={[
                            {
                                value: 'One Panel',
                                key: 'one'
                            },
                            {
                                value: 'Two Panels',
                                key: 'two'
                            }
                        ]}
                        handleChange={handleChangePanelCount}
                        selected={panelCount}
                    />
                </div>
                <div className="flex w-full justify-between gap-4 text-xs text-[var(--light-text-color)]">
                    <div className="flex flex-col gap-2 flex-1">
                        {leftItems.map?.((item, index) => (
                            <div className="flex gap-2 items-center">
                                <p className="w-4">{index * 2 + 1}</p>
                                <TextInput
                                    value={item}
                                    onChange={handleChange.bind(
                                        this,
                                        'left',
                                        index
                                    )}
                                    tabIndex={index * 2 + 1}
                                    placeholder=""
                                    handlePaste={handlePaste.bind(
                                        this,
                                        'left',
                                        index
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col gap-2 flex-1">
                        {rightItems.map?.((item, index) => (
                            <div className="flex gap-2 items-center">
                                <p className="w-4 text-right">
                                    {(index + 1) * 2}
                                </p>
                                <TextInput
                                    value={item}
                                    onChange={handleChange.bind(
                                        this,
                                        'right',
                                        index
                                    )}
                                    tabIndex={(index + 1) * 2}
                                    handlePaste={handlePaste.bind(
                                        this,
                                        'right',
                                        index
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    onClick={handleClose}
                    label={'Cancel'}
                    type="secondary"
                />
                <Button onClick={handleAddPanel} label={'Confirm'} />
            </Modal.Actions>
        </Modal>
    );
};

export default AddBranchCircuitPanel;
