import useApi from '../../../../hooks/useApi';

import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import ErrorMessage from '@/components/general/ErrorMessage';
import { useSnackbar } from 'notistack';

type DeleteTerritoryAssignmentModalProps = {
    territoryAssignments: any[];
    handleClose: () => null;
    handleDelete: (deletedTerritoryAssignments: any) => null;
};

const DeleteTerritoryAssignmentModal = ({
    territoryAssignments,
    handleClose,
    handleDelete
}: DeleteTerritoryAssignmentModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [
        { loading: removingAssignments, error: assignmentError },
        removingTerritoryAssignment
    ] = useApi('', 'POST', { manual: true });

    const handleRemove = () => {
        removingTerritoryAssignment({
            url: `connectwise/territory/assignments/delete`,
            data: {
                territoryAssignmentIds: territoryAssignments.map((ta) => ta.id)
            }
        })
            .then((result: any) => {
                if (result.failedRequestIds.length === 0) {
                    enqueueSnackbar({
                        message: 'Territory assignment(s) deleted',
                        variant: 'success'
                    });
                } else {
                    enqueueSnackbar({
                        message: 'Some territory assignments were not deleted',
                        variant: 'warning'
                    });
                }
                handleDelete(result.successfulRequestIds);
                handleClose();
            })
            .catch((err: unknown) => {
                console.error(err);
                enqueueSnackbar('Could not delete territory assignments.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title>Delete Territory Assignment</Modal.Title>
            <Modal.Body>
                <div className="flex flex-col gap-4 pb-4">
                    <div className="text-sm text-[var(--light-text-color)] border-b-[var(--xlight-text-color)] border-b pb-4">
                        Deleting {territoryAssignments?.length ?? 0} territory
                        assignment(s).
                    </div>
                    {assignmentError && (
                        <ErrorMessage error={assignmentError?.message} />
                    )}
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Convert"
                    onClick={handleRemove}
                    disabled={removingAssignments}
                    loading={removingAssignments}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteTerritoryAssignmentModal;
