import { DateTime } from 'luxon';

const tryParseJSON = (obj, defaultValue) => {
    try {
        return JSON.parse(obj);
    } catch (e) {
        return defaultValue ?? obj;
    }
};

const tryParseDate = (date) => {
    try {
        if (date === null || date instanceof Date) {
            return date;
        }
        return DateTime.fromISO(date).toJSDate();
    } catch (e) {
        return null;
    }
};

const removeNulls = (arr) =>
    arr?.filter?.((a) => a !== null && a !== undefined);

const formatPhoneNumber = (string) => {
    var cleaned = ('' + string).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

export { formatPhoneNumber, removeNulls, tryParseJSON, tryParseDate };
