import useApi from '@hooks/useApi';

import ErrorMessage from '@/components/general/ErrorMessage';
import { TextTooltip } from '@/components/general/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingOverlay from '@general/LoadingOverlay';
import Modal from '@general/modal/Modal';

type DetailModalProps = {
    handleClose: () => null;
    handleTandM: () => null;
    handleAgreement: () => null;
    handleProject: () => null;
    handleNoCharge: () => null;
    workOrder: any;
};

const DetailModal = ({
    workOrder,
    handleClose,
    handleTandM,
    handleAgreement,
    handleProject,
    handleNoCharge
}: DetailModalProps) => {
    const [{ data: time, loading, error }, retrieveTime] = useApi(
        `/connectwise/serviceticket/${workOrder?.id}/time`,
        'GET'
    );

    const handleAction = (action: (workOrder: any) => any) => {
        handleClose();
        action(workOrder);
    };

    return (
        <Modal
            open
            handleClose={handleClose}
            blocking
            classes={{
                content: 'overflow-hidden'
            }}
        >
            <Modal.Body
                contentClassName={'!px-[0] !py-[0] flex-1 min-h-full'}
                containerClassName="min-h-full"
            >
                <div className="flex flex-col gap-4 h-full">
                    <div className="flex items-center min-h-[48px] px-4 pr-6 bg-gray-200">
                        <TextTooltip tooltip={workOrder?.summary}>
                            {workOrder?.summary ?? 'Summary'}
                        </TextTooltip>
                    </div>
                    <div className="px-4">
                        <div className="flex justify-between gap-2">
                            <div className="flex-1">
                                <div className="text-xs text-[var(--light-text-color)]">
                                    Company
                                </div>
                                <div className="text-sm text-[var(--medium-text-color)]">
                                    {workOrder?.company?.name}
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="text-xs text-[var(--light-text-color)]">
                                    Opportunity
                                </div>
                                <div className="text-sm text-[var(--medium-text-color)]">
                                    {workOrder?.opportunity?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between min-h-[48px] px-4 bg-gray-100">
                            <p>Time Entries</p>
                            <div className="flex gap-1 text-[var(--xlight-text-color)] text-sm">
                                <span
                                    className="hover:text-[var(--medium-text-color)] cursor-pointer p-1"
                                    onClick={handleAction.bind(
                                        this,
                                        handleNoCharge
                                    )}
                                >
                                    <TextTooltip
                                        tooltip="No Charge"
                                        hoverTrigger="always"
                                    >
                                        <FontAwesomeIcon icon="ban" />
                                    </TextTooltip>
                                </span>
                                <span
                                    className="hover:text-[var(--light-text-color)] cursor-pointer p-1"
                                    onClick={handleAction.bind(
                                        this,
                                        handleTandM
                                    )}
                                >
                                    <TextTooltip
                                        tooltip="T&M"
                                        hoverTrigger="always"
                                    >
                                        <FontAwesomeIcon icon="business-time" />
                                    </TextTooltip>
                                </span>
                                <span
                                    className="hover:text-[var(--medium-text-color)] cursor-pointer p-1"
                                    onClick={handleAction.bind(
                                        this,
                                        handleAgreement
                                    )}
                                >
                                    <TextTooltip
                                        tooltip="Agreement"
                                        hoverTrigger="always"
                                    >
                                        <FontAwesomeIcon icon="handshake" />
                                    </TextTooltip>
                                </span>
                                <span
                                    className="hover:text-[var(--medium-text-color)] cursor-pointer p-1"
                                    onClick={handleAction.bind(
                                        this,
                                        handleProject
                                    )}
                                >
                                    <TextTooltip
                                        tooltip="Project"
                                        hoverTrigger="always"
                                    >
                                        <FontAwesomeIcon icon="diagram-project" />
                                    </TextTooltip>
                                </span>
                            </div>
                        </div>
                        <div className="min-h-[200px] overflow-auto max-h-300px flex-1">
                            {error ? (
                                <div className="px-4 text-sm text-[var(--light-text-color)]">
                                    <ErrorMessage
                                        error={
                                            <p>
                                                We could not retrieve the time
                                                records for this work order,
                                                please{' '}
                                                <span
                                                    className="underline cursor-pointer hover:text-[var(--medium-text-color)]"
                                                    onClick={retrieveTime}
                                                >
                                                    try again
                                                </span>
                                                . <span>{error?.message}.</span>
                                            </p>
                                        }
                                    />
                                </div>
                            ) : loading ? (
                                <div className="w-full h-[200px] flex items-center justify-center">
                                    <LoadingOverlay />
                                </div>
                            ) : time.length === 0 ? (
                                <div className="text-[var(--xlight-text-color)] text-sm flex items-center justify-center w-full h-[200px]">
                                    <p>
                                        There are no time entries associated
                                        with this ticket.
                                    </p>
                                </div>
                            ) : (
                                time.map((t: any) => (
                                    <div className="flex flex-col gap-1 px-4 py-2 text-[var(--light-text-color)] text-sm border-b border-b-gray-100">
                                        <p className="text-[var(--medium-text-color)]">
                                            {t.member?.name} ({t.actualHours}{' '}
                                            {t.workType?.name})
                                        </p>
                                        <p className="text-xs flex gap-2 text-[var(--xlight-text-color)]">
                                            {new Date(
                                                t.timeStart
                                            ).toLocaleDateString('en-us', {
                                                year: '2-digit',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hourCycle: 'h11'
                                            })}
                                            <span>-</span>
                                            {new Date(
                                                t.timeEnd
                                            ).toLocaleDateString('en-us', {
                                                year: '2-digit',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hourCycle: 'h11'
                                            })}
                                        </p>
                                        <p>{t.notes}</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DetailModal;
