import React, { useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useDimensions from '../../../hooks/useDimensions';

import { recaptchaSiteKey } from '../../../js/services/api';
import Button from '../../general/input/Button';
import Checkbox from '../../general/input/Checkbox';
import Dropdown from '../../general/input/Dropdown';
import FileUpload from '../../general/input/FileUpload';
import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import SubmitSuggestionModal from './modal/SubmitSuggestionModal';
import { useIsAuthenticated } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';

import styles from '../../../styles/apps/suggestion_box/SuggestionForm.module.scss';

const SuggestionForm = () => {
    const [width] = useDimensions();

    const isAuthenticated = useIsAuthenticated();
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading }, postSuggestion] = useApi('/suggestions', 'POST', {
        manual: true
    });
    const [{ data: categories }] = useApi('/suggestions/categories', 'GET');

    const captchaRef = useRef();
    const submitButtonRef = useRef();

    const { handleSubmit, control, reset, setError, setValue } =
        useForm({
            mode: 'onSubmit',
            reValidateMode: 'onSubmit',
            criteriaMode: 'all',
            defaultValues: {
                areaOfSuggestion: null,
                name: '',
                followUp: false,
                jobSite: '',
                suggestion: '',
                attachments: [],
                CAPTCHA: null
            }
        });

    //Modal state
    const [success, setSuccess] = useState(false);

    const handleSuccessClose = () => setSuccess(false);

    const clearFields = () => {
        reset();
        captchaRef?.current?.reset();
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const handleCreate = (data) => {
        if (!isAuthenticated && !data.CAPTCHA) {
            return setError('CAPTCHA', 'You must complete the CAPTCHA.');
        }

        let formData = new FormData();

        formData.append(
            'jsonData',
            JSON.stringify({
                categoryId: data.areaOfSuggestion.key,
                feedback: data.suggestion,
                jobName: data.jobSite,
                suggesterName: data.name,
                isFollowUpRequested:
                    data.name !== null && data.name !== ''
                        ? data.followUp
                        : false
            })
        );

        if (!isAuthenticated)
            formData.append('g-recaptcha-response', data.CAPTCHA);

        data.attachments.forEach((attachment) =>
            formData.append('attachment', attachment.file)
        );

        postSuggestion({
            data: formData
        })
            .then(() => {
                clearFields();
                setSuccess(true);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    setValue('CAPTCHA', null);
                    captchaRef?.current?.reset();
                    enqueueSnackbar(
                        'CAPTCHA verification failed, please try again.',
                        {
                            variant: 'error',
                            autoHideDuration: 3000,
                            preventDuplicate: true
                        }
                    );
                } else {
                    enqueueSnackbar(err?.response?.data ?? 'Failed to add your suggestion.', {
                        variant: 'error',
                        autoHideDuration: 3000,
                        preventDuplicate: true
                    });
                }
            });
    };

    return (
        <div className={styles.root}>
            <form
                autoComplete="true"
                onSubmit={handleSubmit(handleCreate)}
                noValidate={true}
            >
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1 className={styles.title}>Suggestion Box</h1>
                        <p className={styles.subtitle}>
                            Please submit concerns/suggestions for review by
                            appropriate personnel. Suggestions may be submitted
                            anonymously, but please include your name and check
                            “Request Follow-up” if you would like a response.
                            Thank you!
                        </p>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.section}>
                            <Controller
                                name="areaOfSuggestion"
                                control={control}
                                rules={{
                                    required:
                                        'You must select an area for your suggestion.'
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        required={true}
                                        label="Area of Suggestion"
                                        classes={{
                                            container:
                                                styles['area-of-suggestion']
                                        }}
                                        error={error?.message}
                                    >
                                        <Dropdown
                                            options={
                                                categories
                                                    ? categories.map(
                                                          (category) => ({
                                                              key: category.id,
                                                              value: category.name
                                                          })
                                                      )
                                                    : []
                                            }
                                            selected={value}
                                            handleSelect={onChange}
                                            placeholder="Area of Suggestion"
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.section}>
                            <Controller
                                name="name"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        required={false}
                                        label="Name (optional)"
                                        classes={{ container: styles.name }}
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Name"
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { value: name } }) =>
                                    name && (
                                        <Controller
                                            name="followUp"
                                            control={control}
                                            render={({
                                                field: { value, onChange },
                                                fieldState: { error }
                                            }) => (
                                                <InputLabel
                                                    position="right"
                                                    label="Request Follow-up"
                                                    classes={{
                                                        container:
                                                            styles.followup
                                                    }}
                                                    error={error?.message}
                                                >
                                                    <Checkbox
                                                        initialChecked={false}
                                                        checked={value}
                                                        handleChange={onChange.bind(this, !value)}
                                                    />
                                                </InputLabel>
                                            )}
                                        />
                                    )
                                }
                            />
                        </div>
                        <div className={styles.section}>
                            <Controller
                                name="jobName"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        required={false}
                                        label="Job Name (optional)"
                                        classes={{ container: styles.jobsite }}
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Job Name"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div
                            className={[
                                styles.section,
                                styles['vertical-section']
                            ].join(' ')}
                        >
                            <Controller
                                name="suggestion"
                                control={control}
                                rules={{
                                    required: 'You must provide a suggestion.',
                                    validate: (suggestion) =>
                                        suggestion.trim() === ''
                                            ? 'You must provide a suggestion.'
                                            : null
                                }}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        required={true}
                                        label="Suggestion"
                                        classes={{
                                            container: styles.suggestion
                                        }}
                                        error={error?.message}
                                    >
                                        <TextInput
                                            placeholder="Suggestion"
                                            lines={8}
                                            value={value}
                                            onChange={onChange}
                                            inputRef={ref}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {isAuthenticated && <div
                            className={[
                                styles.section,
                                styles['vertical-section']
                            ].join(' ')}
                        >
                            <Controller
                                name="attachments"
                                control={control}
                                render={({
                                    field: { value, onChange, ref },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Attachments"
                                        labelClick={false}
                                        error={error?.message}
                                    >
                                        <FileUpload
                                            handleChange={onChange}
                                            files={value}
                                            width={
                                                width < 630 ? '100%' : '400px'
                                            }
                                            validFileTypes={[
                                                'application/pdf',
                                                'image/jpeg',
                                                'image/png'
                                            ]}
                                            validFileExtensions={['.pdf','.jpg','.jpeg','.png']}
                                            uploadLimit={1}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>}
                        {!isAuthenticated && (
                            <div className={[styles.section].join(' ')}>
                                <Controller
                                    name="CAPTCHA"
                                    control={control}
                                    render={({
                                        field: { onChange, ref },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel error={error?.message}>
                                            <ReCAPTCHA
                                                sitekey={recaptchaSiteKey}
                                                onChange={onChange}
                                                ref={ref}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                        )}
                        <div
                            className={[
                                styles.section,
                                styles['flex-end'],
                                width < 456
                                    ? styles['vertical-section']
                                    : styles['horizontal-section']
                            ].join(' ')}
                        >
                            <div className={styles.disclaimer}>
                                <p className={styles.red}>*</p> Submissions are
                                anonymous unless a name is provided
                            </div>
                            <Button
                                label="Submit"
                                type="primary"
                                onClick={handleSubmitClicked}
                                loading={loading}
                                className={styles.submit}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        visibility: 'hidden',
                        opacity: 0
                    }}
                >
                    <button formAction="submit" ref={submitButtonRef} />
                </div>
            </form>
            <SubmitSuggestionModal
                open={success}
                blocking={false}
                handleClose={handleSuccessClose}
            />
        </div>
    );
};

export default SuggestionForm;
