import { useState } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import InputLabel from '@/components/general/input/InputLabel';
import TextInput from '@/components/general/input/TextInput';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { isNullOrEmpty } from '@/js/services/validation';

const TAndMModal = ({ tickets, handleClose, handleConversions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading: closingTickets, error: closingError }, closeTickets] =
        useApi('', 'PUT', { manual: true });

    const [rate, setRate] = useState('');

    const handleCloseTandM = () => {
        if (!tickets || tickets.length === 0) {
            return;
        }
        closeTickets({
            url: `connectwise/serviceticket/convert/tandm`,
            data: {
                serviceTickets: tickets,
                rate: isNullOrEmpty(rate) ? null : rate
            }
        }).then((result) => {
            if (result.failedRequestIds.length === 0) {
                enqueueSnackbar({
                    message: 'Ticket(s) closed',
                    variant: 'success'
                });
            } else {
                enqueueSnackbar({
                    message: 'Some Ticket(s) were not closed',
                    variant: 'warning'
                });
            }
            handleConversions(result.successfulRequestIds);
            handleClose();
        });
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title>Close As T&M</Modal.Title>
            <Modal.Body>
                <div className="flex flex-col gap-4 pb-4">
                    <div className="text-sm text-[var(--light-text-color)] border-b-[var(--xlight-text-color)] border-b pb-4">
                        Closing {tickets?.length ?? 0} service ticket(s) as T&M
                    </div>
                    <div>
                        <InputLabel
                            label="Rate Override"
                            disclaimer="No value will result in the default value being used"
                        >
                            <TextInput
                                icon={faDollarSign}
                                value={rate}
                                validate="decimal"
                                placeholder="0.00"
                                classes={{
                                    input: 'max-w-[160px]'
                                }}
                                onChange={(e) => setRate(e.target.value)}
                                disabled={closingTickets}
                            />
                        </InputLabel>
                    </div>
                </div>
                {closingError && <ErrorMessage error={closingError?.message} />}
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Confirm"
                    onClick={handleCloseTandM}
                    disabled={closingTickets}
                    loading={closingTickets}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default TAndMModal;
