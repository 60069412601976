import React, { useEffect, useState } from 'react';

import useApi from '../../../../hooks/useApi';

import DatePicker from '../../../general/input/DatePicker';
import FileUpload from '../../../general/input/FileUpload';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import LoadingOverlay from '../../../general/LoadingOverlay';
import Modal from '../../../general/modal/Modal';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import styles from '../../../../styles/apps/certifications/EditEmployeeCertificationModal.module.scss';

const EditEmployeeCertificationModal = ({
    open,
    blocking,
    handleClose = () => null,
    certificationId,
    onSaved = () => null
}) => {
    //Retrieve the employee certification
    const [{ data: employeeCertification, loading: certificationLoading }] =
        useApi(`/employee-certifications/${certificationId}`, 'GET');
    const [{ loading: saveLoading }, saveCertification] = useApi(
        `/employee-certifications/${certificationId}`,
        'PUT',
        { manual: true }
    );
    const [{ loading: loadingAttachments }, getAttachments] = useApi(
        `/employee-certifications/${certificationId}/attachments`,
        'GET',
        { manual: true }
    );

    const [attachments, setAttachments] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [employeesError, setEmployeesError] = useState(null);
    const [certification, setCertification] = useState([]);
    const [expiresDate, setExpiresDate] = useState(null);
    const [expiresError, setExpiresError] = useState(null);
    const [receivedDate, setReceivedDate] = useState(null);
    const [receivedError, setReceivedError] = useState(null);
    const [removedAttachments, setRemovedAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const handleEmployeeChange = (employees) => setEmployees(employees);
    const handleCertificationChange = (certifications) =>
        setCertification(certifications);
    const handleReceiveChange = (receive) => setReceivedDate(receive);
    const handleExpireChange = (expire) => setExpiresDate(expire);

    let handleRemoveAttachment = (id) =>
        isNumber(id) && setRemovedAttachments((removed) => [...removed, id]);

    let handleAttachmentChange = (attachments) => {
        setAttachments(attachments);
    };

    const resetErrors = () => {
        setEmployeesError(null);
        setReceivedError(null);
        setAttachmentError(null);
    };

    const handleSaveCertification = () => {
        resetErrors();
        let formData = new FormData();

        if (!employees || employees?.length === 0) {
            return setEmployeesError('You must specify an employee.');
        }

        if (!receivedDate) {
            return setReceivedError('You must specify a received date.');
        }

        if (receivedDate <= new Date('1970-01-01T00:00:00')) {
            return setReceivedError(
                'Please provide a 4 digit year after 1970.'
            );
        }

        if (expiresDate && new Date(receivedDate) > new Date(expiresDate)) {
            return setExpiresError('Expires date must be after Received date.');
        }

        if (attachments?.length <= 0) {
            return setAttachmentError(
                'You must include an attachment for this certification.'
            );
        }

        formData.append(
            'jsonData',
            JSON.stringify({
                employeeId: employees[0].id,
                expiresOn: expiresDate
                    ? DateTime.fromJSDate(new Date(expiresDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certifiedOn: receivedDate
                    ? DateTime.fromJSDate(new Date(receivedDate)).toFormat(
                          'yyyy-MM-dd'
                      )
                    : null,
                certificationTypeId: certification[0].id,
                removedAttachmentIds: removedAttachments
            })
        );

        attachments
            .filter((a) => !isNumber(a.id) && a.file)
            .forEach((attachment) =>
                formData.append(attachment.filename, attachment.file)
            );

        saveCertification({
            data: formData
        })
            .then((row) => {
                handleClose();
                enqueueSnackbar('Employee certification saved.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                onSaved(row);
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    err?.response?.data?.detail ??
                        'Error encountered while saving the certification.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    };

    useEffect(() => {
        if (!employeeCertification) return;
        setReceivedDate(
            employeeCertification?.certifiedOn
                ? DateTime.fromISO(employeeCertification?.certifiedOn, {
                      zone: 'utc'
                  })
                      .setZone('local', { keepLocalTime: true })
                      .toJSDate()
                : null
        );
        setExpiresDate(
            employeeCertification?.expiresOn &&
                employeeCertification?.expiresOn >=
                    employeeCertification?.certifiedOn
                ? DateTime.fromISO(employeeCertification?.expiresOn, {
                      zone: 'utc'
                  })
                      .setZone('local', { keepLocalTime: true })
                      .toJSDate()
                : null
        );
        setEmployees([
            {
                id: employeeCertification.employeeId,
                firstName: employeeCertification.firstName,
                lastName: employeeCertification.lastName,
                middleName: employeeCertification.middleName
            }
        ]);
        setCertification([
            {
                id: employeeCertification.certificationTypeId,
                code: employeeCertification.code,
                description: employeeCertification.description
            }
        ]);
    }, [employeeCertification]);

    useEffect(() => {
        getAttachments()
            .then((attachments) => {
                setAttachments(
                    attachments.map((a) => ({
                        ...a,
                        download: `/employee-certifications/${certificationId}/attachments/${a?.id}/download`,
                        preview: `/employee-certifications/${certificationId}/attachments/${a?.id}/preview`
                    }))
                );
            })
            .catch((err) => {
                console.error('err', err);
                enqueueSnackbar(
                    'Error encountered while retrieving attachments.',
                    {
                        variant: 'error',
                        autoHideDuration: 3000
                    }
                );
            });
    }, []); //eslint-disable-line

    return (
        <Modal
            open={open}
            blocking={blocking}
            handleClose={handleClose}
            style={{
                content: {
                    minHeight: '477px'
                }
            }}
        >
            <Modal.Title>Edit Employee Certification</Modal.Title>
            <Modal.Body>
                <div className={styles.container}>
                    {certificationLoading || !employeeCertification ? (
                        <LoadingOverlay
                            size="md"
                            spinColor="rgba(0,0,0,0.4)"
                            backgroundColor="rgba(0,0,0,0.1)"
                            label="Loading Certification..."
                            fontSize="14px"
                            labelPosition="right"
                        />
                    ) : (
                        <div className={styles.section}>
                            <div className={styles.detailGroup}>
                                <div
                                    className={[
                                        styles.detailProperty,
                                        styles.flexGrow
                                    ].join(' ')}
                                    style={{ maxWidth: '309px' }}
                                >
                                    <InputLabel
                                        label="Employee"
                                        required={true}
                                        error={employeesError}
                                    >
                                        <Select
                                            handleRowSelection={
                                                handleEmployeeChange
                                            }
                                            selected={employees}
                                            getRowValue={(row) =>
                                                row?.firstName || row?.lastName
                                                    ? `${row?.lastName}, ${
                                                          row?.firstName
                                                      } ${
                                                          row?.middleName ?? ''
                                                      }`
                                                    : undefined
                                            }
                                            getRowId={(row) => row.id}
                                            pagination={{
                                                url: '/employees'
                                            }}
                                            placeholder="Employee Name"
                                            sort={[
                                                'LastName',
                                                'FirstName',
                                                'MiddleName',
                                                'Suffix',
                                                'EmployeeNumber'
                                            ]}
                                        />
                                    </InputLabel>
                                </div>
                                <div className={styles.detailProperty}>
                                    <InputLabel
                                        label="Received"
                                        disclaimer="ex: 09/12/2021"
                                        required={true}
                                        error={receivedError}
                                    >
                                        <DatePicker
                                            value={receivedDate}
                                            onChange={handleReceiveChange}
                                        />
                                    </InputLabel>
                                </div>
                            </div>
                            <div className={styles.detailGroup}>
                                <div
                                    className={[
                                        styles.detailProperty,
                                        styles.flexGrow
                                    ].join(' ')}
                                    style={{ maxWidth: '309px' }}
                                >
                                    <InputLabel label="Certification">
                                        <Select
                                            handleRowSelection={
                                                handleCertificationChange
                                            }
                                            selected={certification}
                                            getRowValue={(row) =>
                                                row?.code || row?.description
                                                    ? `${row?.code} - ${row?.description}`
                                                    : undefined
                                            }
                                            getRowId={(row) => row.id}
                                            pagination={{
                                                url: '/certification-types'
                                            }}
                                            placeholder="Certification Name"
                                            sort={['Code', 'Description']}
                                        />
                                    </InputLabel>
                                </div>
                                <div className={styles.detailProperty}>
                                    <InputLabel
                                        label="Expires"
                                        error={expiresError}
                                    >
                                        <DatePicker
                                            value={expiresDate}
                                            onChange={handleExpireChange}
                                        />
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.section}>
                        <div className={styles.detailGroup}>
                            <div className={styles.detailProperty}>
                                <InputLabel
                                    label="Attachments"
                                    required={true}
                                    error={attachmentError}
                                    labelClick={false}
                                >
                                    {loadingAttachments || !attachments ? (
                                        <LoadingOverlay
                                            size="xs"
                                            spinColor="rgba(0,0,0,0.4)"
                                            backgroundColor="rgba(0,0,0,0.1)"
                                            label="Loading..."
                                            fontSize="12px"
                                            labelPosition="right"
                                        />
                                    ) : (
                                        <FileUpload
                                            files={attachments}
                                            downloadSingle={true}
                                            emptyMessage={
                                                attachmentError &&
                                                attachmentError?.response
                                                    ?.status === 403
                                                    ? "You don't have permissions to view the attachments."
                                                    : attachmentError
                                                    ? 'Error retrieving attachments'
                                                    : 'No attachments added.'
                                            }
                                            downloadAll={`/certifications/${certificationId}/attachments/download`}
                                            handleRemove={
                                                handleRemoveAttachment
                                            }
                                            handleChange={
                                                handleAttachmentChange
                                            }
                                        />
                                    )}
                                </InputLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions
                buttons={[
                    {
                        label: 'Cancel',
                        onClick: handleClose,
                        type: 'secondary',
                        variant: 'border'
                    },
                    {
                        label: 'Confirm',
                        loading: saveLoading,
                        onClick: handleSaveCertification,
                        type: 'primary'
                    }
                ]}
            />
        </Modal>
    );
};

EditEmployeeCertificationModal.propTypes = {
    open: PropTypes.bool,
    blocking: PropTypes.bool,
    handleClose: PropTypes.func,
    certificationId: PropTypes.arrayOf(PropTypes.number),
    onSaved: PropTypes.func
};

export default EditEmployeeCertificationModal;
