import { useState } from 'react';

import determineComponent from '../../../js/services/form-inputs';
import { tryParseJSON } from '../../../js/services/manipulation';
import Button from '../input/Button';
import Modal from '../modal/Modal';
import AddBranchCircuitPanel from './modal/AddBranchCircuitPanel';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFieldArray } from 'react-hook-form';

import styles from '../../../styles/general/forminputs/FormDynamic.module.scss';

const FormBranchCircuit = ({
    control,
    error,
    id,
    isEditable,
    props,
    styles: style,
    value
}) => {
    const [deleteModal, setDeleteModal] = useState(null);
    const [addModal, setAddModal] = useState(null);

    const parsedProps = props ? JSON.parse(props) : {};
    const {
        fields: dynamicField,
        style: dynamicStyle,
        containerStyle
    } = parsedProps;

    const {
        fields: panels,
        append,
        remove
    } = useFieldArray({
        name: `${id}`,
        control
    });

    const handleNewPanel = (panels) => {
        append(
            panels.map((panel) => {
                return panel.map((p) =>
                    dynamicField?.reduce?.(
                        (acc, cur) => ({
                            ...acc,
                            [cur.id]:
                                cur.id === 'description'
                                    ? p
                                    : cur.defaultValue ?? null
                        }),
                        {}
                    )
                );
            })
        );
    };

    const handleRemovePanel = (index) => {
        setDeleteModal(index);
    };

    const handleCloseDeleteModal = () => setDeleteModal(null);
    const handleCloseAddModal = () => setAddModal(null);

    const handleConfirmDelete = (index) => {
        remove(index);
        setDeleteModal(null);
    };

    return (
        <div style={{ width: '100%' }}>
            <div className="flex border-b border-b-black">
                <div className="flex flex-1 text-sm [&>p:not(:last-child)]:border-r [&>p]:border-r-black border-r border-r-black [&>p]:px-2 [&>p]:text-center">
                    {dynamicField.map((field) => (
                        <p
                            className={field.className}
                            key={field.id}
                            style={field.style}
                        >
                            {field.header}
                        </p>
                    ))}
                </div>
                <div className="flex flex-1 text-sm [&>p:not(:last-child)]:border-r [&>p]:border-r-black [&>p]:px-2 border-l border-l-black [&>p]:text-center">
                    {dynamicField.map((field) => (
                        <p
                            className={field.className}
                            key={field.id}
                            style={field.style}
                        >
                            {field.header}
                        </p>
                    ))}
                </div>
            </div>
            <div>
                {panels &&
                    Array.isArray(panels) &&
                    panels.map((outerPanel, panelIndex) => (
                        <Panel
                            control={control}
                            fieldId={id}
                            panelId={panelIndex}
                            dynamicField={dynamicField}
                            isEditable={isEditable}
                            handleRemovePanel={handleRemovePanel}
                            style={dynamicStyle}
                            containerStyle={containerStyle}
                            key={outerPanel.id}
                        />
                    ))}
                {addModal && (
                    <AddBranchCircuitPanel
                        handleSave={handleNewPanel}
                        handleClose={handleCloseAddModal}
                    />
                )}
                {deleteModal !== null && (
                    <Modal open handleClose={handleCloseDeleteModal}>
                        <Modal.Title>
                            {dynamicField?.deletePrompt ?? 'Delete Panel'}
                        </Modal.Title>
                        <Modal.Body>
                            <div className={styles.confirmationMessage}>
                                {dynamicField?.deleteMessage ??
                                    'Are you sure you want to delete this panel?'}
                            </div>
                        </Modal.Body>
                        <Modal.Actions>
                            <Button
                                onClick={handleCloseDeleteModal}
                                label={'Cancel'}
                                type="secondary"
                            />
                            <Button
                                onClick={handleConfirmDelete.bind(
                                    this,
                                    deleteModal
                                )}
                                label={'Confirm'}
                            />
                        </Modal.Actions>
                    </Modal>
                )}
                {isEditable && (
                    <div
                        className={[
                            styles.newItem,
                            'border-t border-t-black'
                        ].join(' ')}
                        onClick={() => setAddModal(true)}
                        style={dynamicField?.buttonStyle}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            fontSize={'.7rem'}
                            className={styles.icon}
                        />
                        <p>{dynamicField?.newPrompt ?? 'New Panel'}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const Panel = ({
    control,
    fieldId,
    panelId,
    dynamicField,
    isEditable,
    handleRemovePanel,
    style,
    containerStyle
}) => {
    const { fields: panel } = useFieldArray({
        name: `${fieldId}.${panelId}`,
        control
    });

    return (
        <div style={containerStyle} className='relative'>
            {panel.map?.((v, i) => (
                <div
                    className="[&>div:not(:last-child)]:border-r [&>div:not(:last-child)]:border-r-black [&:nth-child(odd)]:border-r-black [&:nth-child(odd)]:border-r [&:nth-child(even)]:border-l-black [&:nth-child(even)]:border-l"
                    style={style}
                    key={v.id}
                >
                    {dynamicField?.map?.((f, fieldIndex) => (
                        <div
                            style={tryParseJSON(f.style) ?? {}}
                            className={`${f.className} border-t border-t-black mt-[-1px]`}
                            key={fieldIndex}
                        >
                            <FormDynamicLineItem
                                control={control}
                                dynamicFieldId={`${fieldId}.${panelId}`}
                                isEditable={isEditable}
                                item={f}
                                index={i}
                                panelIndex={panelId}
                            />
                        </div>
                    ))}
                </div>
            ))}
            {isEditable && (
                <div
                    className={[
                        styles.removeLine,
                        'absolute left-[calc(100%+8px)] top-[50%] translate-y-[-50%]'
                    ].join(' ')}
                    style={dynamicField?.deleteIconStyle}
                    onClick={handleRemovePanel.bind(this, panelId)}
                >
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        className={[
                            styles.removeIcon,
                            'text-[var(--xlight-text-color)] hover:text-[var(--medium-text-color)] cursor-pointer'
                        ].join(' ')}
                    />
                </div>
            )}
        </div>
    );
};

const FormDynamicLineItem = ({
    control,
    dynamicFieldId,
    isEditable,
    item,
    index,
    panelIndex
}) => (
    <Controller
        control={control}
        name={`${dynamicFieldId}.${index}.${item?.id}`}
        render={({ field: { value, onChange }, fieldState: { error } }) =>
            determineComponent({
                type: item.type,
                props: JSON.stringify({
                    ...(item.props ?? {}),
                    label: item.props?.label?.includes?.('{{index}}')
                        ? item.props.label.replace(
                              '{{index}}',
                              42 * panelIndex + (index + 1)
                          )
                        : null,
                    options:
                        item.props?.options && tryParseJSON(item.props.options)
                }),
                isEditable,
                value: tryParseJSON(value),
                onChange: onChange,
                error: error
            })
        }
    />
);

export default FormBranchCircuit;
