import React from 'react';

import determineComponent from '../../../js/services/form-inputs';
import { Controller } from 'react-hook-form';

const FormField = ({ control, debug, readOnly, ...props }) => {
    let styles = JSON.parse(props?.style ?? '{}');
    if (!props.isEditable)
        styles = {
            ...styles,
            ...(styles?.readOnly ?? {})
        };

    let { type } = props;

    return ['dynamic', 'branchcircuit'].includes(type) ? (
        determineComponent({
            ...props,
            control,
            readOnly,
            styles
        })
    ) : (
        <React.Fragment>
            <Controller
                control={control}
                name={`${props.id}`}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) =>
                    determineComponent({
                        ...props,
                        readOnly,
                        onChange,
                        value,
                        error,
                        styles
                    })
                }
            />
            {((props?.required && props?.isEditable && !readOnly) || debug) && (
                <p
                    style={{
                        color: 'red',
                        position: 'absolute',
                        fontSize: '.75rem',
                        top: 3,
                        right: 3,
                        zIndex: 0
                    }}
                >
                    * {debug && props.id}
                </p>
            )}
        </React.Fragment>
    );
};

export default FormField;
