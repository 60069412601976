import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import TextInput from '@/components/general/input/TextInput';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type NewTerritoryAssignmentModalProps = {
    handleClose: () => null;
    handleNew: (newTerritoryAssignments: any) => null;
};

const NewTerritoryAssignmentModal = ({
    handleClose,
    handleNew
}: NewTerritoryAssignmentModalProps) => {
    const submitButtonRef = useRef<HTMLButtonElement>(null);

    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        setValue,
        getValues,
        watch,
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            email: '',
            territories: []
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    const [
        { loading: addingAssignment, error: assignmentError },
        addTerritoryAssignment
    ] = useApi('', 'POST', { manual: true });

    const handleCreate = ({
        email,
        territories
    }: {
        email: string;
        territories: any[];
    }) => {
        addTerritoryAssignment({
            url: `connectwise/territory/assignments`,
            data: {
                email,
                territories: territories
            }
        })
            .then((result: any) => {
                handleNew(result);
                enqueueSnackbar(
                    `Added ${result.length} territory assignments.`,
                    {
                        variant: 'success',
                        autoHideDuration: 3000
                    }
                );
                handleClose();
            })
            .catch((err: unknown) => {
                console.error(err);
                enqueueSnackbar('Could not add territory assignments', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title>New Territory Assignment</Modal.Title>
            <Modal.Body>
                <div className="flex flex-col gap-4 pb-4">
                    <FormProvider
                        handleSubmit={handleSubmit}
                        control={control as any}
                        {...{
                            ...methods,
                            setError,
                            clearErrors,
                            setValue,
                            getValues,
                            watch
                        }}
                    >
                        <form
                            autoComplete="true"
                            onSubmit={(e) => {
                                handleSubmit(handleCreate)(e);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            noValidate={true}
                            className="flex flex-col gap-4"
                        >
                            <Controller
                                name={'email'}
                                control={control}
                                rules={{
                                    required:
                                        'You must specify an email address.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Email Address"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Email Address"
                                        />
                                    </InputLabel>
                                )}
                            />
                            <Controller
                                name={'territories'}
                                control={control}
                                rules={{
                                    required: 'You must specify a territory.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Territories"
                                        error={error?.message}
                                    >
                                        <Select
                                            placeholder={'Territories'}
                                            pagination={{
                                                url: `/connectwise/territory`
                                            }}
                                            multiselect
                                            getRowValue={(row: any) => row.name}
                                            selected={value}
                                            handleRowSelection={onChange}
                                            disabled={addingAssignment}
                                        />
                                    </InputLabel>
                                )}
                            />

                            {assignmentError && (
                                <ErrorMessage
                                    error={assignmentError?.message}
                                />
                            )}
                            <div
                                style={{
                                    visibility: 'hidden',
                                    opacity: 0
                                }}
                            >
                                <button
                                    formAction="submit"
                                    ref={submitButtonRef}
                                />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Submit"
                    onClick={handleSubmitClicked}
                    disabled={addingAssignment}
                    loading={addingAssignment}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewTerritoryAssignmentModal;
