import { useEffect, useRef, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useFilterReducer from '../../../hooks/useFilterReducer';
import useUser from '../../../hooks/useUser';

import ErrorMessage from '../../general/ErrorMessage';
import TextCell from '../../general/grid/cell renderers/TextCell';
import Grid from '../../general/grid/Grid';
import LogoLoader from '../../general/LogoLoader';
import AgreementModal from './modal/AgreementModal';
import DetailModal from './modal/DetailModal';
import NoChargeModal from './modal/NoChargeModal';
import ProjectModal from './modal/ProjectModal';
import TAndMModal from './modal/TAndMModal';
import MenuCell from '@/components/general/grid/cell renderers/MenuCell';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';

const defaultFilterState = {
    companies: {
        value: null,
        getFilter: (companies) =>
            !companies || companies.length === 0
                ? null
                : { CompanyIds: companies.map((c) => c.id) }
    }
};

const ViewWorkOrder = () => {
    const { mail } = useUser();
    const gridRef = useRef();

    const [, getTerritory] = useApi('', 'GET', {
        manual: true,
        promiseOnly: true
    });
    const [, getCompanies] = useApi('', 'GET', { manual: true });

    const [error, setError] = useState(null);
    const [modals, setModals] = useState({
        Tandm: false,
        Agreement: false,
        NoCharge: false,
        Project: false,
        Detail: false
    });
    const [selected, setSelected] = useState(null);

    const { filter, setFilter } = useFilterReducer(defaultFilterState);

    useEffect(() => {
        if (!mail) return;

        getTerritory({
            url: `/connectwise/territory/user?email=${mail}`
        })
            .then((territories) => {
                if (!territories || territories.length === 0) {
                    setError(
                        `Could not fetch the Territory tied to your account in Connectwise.`
                    );
                }
                getCompanies({
                    url: `/connectwise/company?filter={"TerritoryIds":${JSON.stringify(
                        territories
                    )}}&pageSize=1000`
                })
                    .then((companies) => {
                        if (
                            !companies ||
                            !companies.data ||
                            companies.length === 0
                        ) {
                            setError(
                                `Could not fetch the Companies for your Territories in ConnectWise.`
                            );
                        }
                        setFilter({
                            key: 'companies',
                            payload: companies.data
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        setError(
                            `Could not fetch the Companies for your Territories in ConnectWise.`
                        );
                    });
            })
            .catch((err) => {
                console.error(err);
            });
    }, [mail]); //eslint-disable-line

    const handleRowSelection = (selection) => setSelected(selection);

    const handleConvert = (option) => {
        handleOpenModal(option.value ?? option, {
            companies: selectedCompanies[0],
            tickets: selected
        });
    };

    const handleOpenModal = (option, value) => {
        setModals((modal) => ({
            ...modal,
            [option]: value ?? true
        }));
    };

    const handleCloseModal = (option) => {
        setModals((modal) => ({
            ...modal,
            [option]: false
        }));
    };

    const handleConversions = (ids) => {
        ids?.forEach?.((id) => gridRef.current?.removeRow?.(id));
        setSelected([]);
    };

    const selectedCompanies =
        selected?.reduce(
            (acc, sel) =>
                acc.includes(sel.company.id) ? acc : [...acc, sel.company.id],
            []
        ) ?? [];

    return (
        <div className="w-full h-full relative">
            {error ? (
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <div>
                        <p className="text-[var(--light-text-color)] py-4">
                            We ran into an issue retrieving information from
                            ConnectWise.
                        </p>
                    </div>
                    <ErrorMessage error={error?.message} />
                </div>
            ) : !filter.companies?.value ? (
                <LogoLoader />
            ) : (
                <>
                    <Grid
                        ref={gridRef}
                        filters={filter}
                        multisort={false}
                        legend={
                            <div className="text-sm flex flex-col justify-center ml-8 text-[var(--medium-text-color)]">
                                <p>
                                    <span className="font-bold">T&M</span>
                                    <span>
                                        {' '}
                                        - Not associated with a CVEO#, time &
                                        materials invoice.
                                    </span>
                                </p>
                                <p>
                                    <span className="font-bold">Agreement</span>
                                    <span>
                                        {' '}
                                        - Associated with a CVEO#, prepaid
                                        bucket of hours previously invoiced.
                                    </span>
                                </p>
                                <p>
                                    <span className="font-bold">Project</span>
                                    <span>
                                        {' '}
                                        - Associated with a CVEO#, to be
                                        invoiced at project
                                        completion/milestone.
                                    </span>
                                </p>
                            </div>
                        }
                        actions={[
                            {
                                type: 'primary',
                                label: 'Convert',
                                disabled: !selected || selected?.length === 0,
                                icon: faRepeat,
                                handleClick: handleConvert,
                                options: [
                                    {
                                        label: 'No Charge',
                                        value: 'NoCharge',
                                        icon: 'ban'
                                    },
                                    {
                                        label: 'T&M',
                                        value: 'Tandm',
                                        icon: 'business-time'
                                    },
                                    ...(selectedCompanies?.length <= 1
                                        ? [
                                              {
                                                  label: 'Agreement',
                                                  value: 'Agreement',
                                                  icon: 'handshake'
                                              },
                                              {
                                                  label: 'Project',
                                                  value: 'Project',
                                                  icon: 'diagram-project'
                                              }
                                          ]
                                        : [])
                                ]
                            }
                        ]}
                        fixed
                        multiselect
                        rowSelect
                        checkboxSelection
                        handleRowSelection={handleRowSelection}
                        selected={selected}
                        getRowId={(r) => r.id}
                        pagination={{
                            url: `/connectwise/serviceticket`,
                            pageSize: 100
                        }}
                        columns={[
                            {
                                title: 'Company',
                                key: 'company',
                                dataKey: 'company.name',
                                sortKey: 'company/name',
                                width: 300,
                                minWidth: 200,
                                cellRenderer: ({ cellData }) => (
                                    <TextCell>{cellData}</TextCell>
                                )
                            },
                            {
                                title: 'Summary',
                                key: 'summary',
                                dataKey: 'summary',
                                width: 200,
                                minWidth: 200,
                                fixedGrow: 1,
                                cellRenderer: ({ cellData, rowData }) => (
                                    <TextCell>
                                        <p
                                            className="underline cursor-pointer py-4 h-full w-full"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                handleOpenModal(
                                                    'Detail',
                                                    rowData
                                                );
                                            }}
                                        >
                                            {cellData?.replace(
                                                /(re:\s?)?(\[?external\]?)?(:? re:)?/i,
                                                ''
                                            )}
                                        </p>
                                    </TextCell>
                                )
                            },
                            {
                                title: 'Opportunity',
                                key: 'opportunity',
                                dataKey: 'opportunity.name',
                                sortKey: 'opportunity/name',
                                width: 300,
                                minWidth: 140,
                                cellRenderer: ({ cellData }) => (
                                    <TextCell>{cellData}</TextCell>
                                )
                            },
                            {
                                title: 'Work Type',
                                key: 'worktype',
                                dataKey: 'workType.name',
                                sortKey: 'workType/name',
                                width: 200,
                                minWidth: 120,
                                cellRenderer: ({ cellData }) => (
                                    <TextCell>{cellData}</TextCell>
                                )
                            },
                            {
                                title: 'Hours',
                                key: 'hours',
                                dataKey: 'actualHours',
                                sortKey: 'actualHours',
                                width: 100,
                                minWidth: 100,
                                cellRenderer: ({ cellData }) => (
                                    <TextCell>{cellData}</TextCell>
                                )
                            },
                            {
                                title: 'Ticket Age',
                                key: 'dateEntered',
                                dataKey: 'info.dateEntered',
                                sortKey: '_info/dateEntered',
                                width: 100,
                                minWidth: 100,
                                cellRenderer: ({ cellData }) => (
                                    <TextCell>
                                        {formatDateDifference(cellData)}
                                    </TextCell>
                                )
                            },
                            {
                                title: ' ',
                                key: 'actions',
                                sortable: false,
                                width: 50,
                                cellRenderer: ({ rowData }) => (
                                    <MenuCell
                                        options={[
                                            {
                                                icon: 'ban',
                                                label: 'No Charge',
                                                handleClick:
                                                    handleOpenModal.bind(
                                                        this,
                                                        'NoCharge',
                                                        {
                                                            companies:
                                                                rowData?.company
                                                                    ?.id,
                                                            tickets: [rowData]
                                                        }
                                                    )
                                            },
                                            {
                                                icon: 'business-time',
                                                label: 'T&M',
                                                handleClick:
                                                    handleOpenModal.bind(
                                                        this,
                                                        'Tandm',
                                                        {
                                                            companies:
                                                                rowData?.company
                                                                    ?.id,
                                                            tickets: [rowData]
                                                        }
                                                    )
                                            },
                                            {
                                                icon: 'handshake',
                                                label: 'Agreement',
                                                handleClick:
                                                    handleOpenModal.bind(
                                                        this,
                                                        'Agreement',
                                                        {
                                                            companies:
                                                                rowData?.company
                                                                    ?.id,
                                                            tickets: [rowData]
                                                        }
                                                    )
                                            },
                                            {
                                                icon: 'diagram-project',
                                                label: 'Project',
                                                handleClick:
                                                    handleOpenModal.bind(
                                                        this,
                                                        'Project',
                                                        {
                                                            companies:
                                                                rowData?.company
                                                                    ?.id,
                                                            tickets: [rowData]
                                                        }
                                                    )
                                            }
                                        ]}
                                    />
                                )
                            }
                        ]}
                    />
                </>
            )}
            {modals.Agreement && (
                <AgreementModal
                    companies={modals.Agreement.companies}
                    tickets={modals.Agreement.tickets}
                    handleConversions={handleConversions}
                    handleClose={handleCloseModal.bind(this, 'Agreement')}
                />
            )}
            {modals.Project && (
                <ProjectModal
                    companies={modals.Project.companies}
                    tickets={modals.Project.tickets}
                    handleConversions={handleConversions}
                    handleClose={handleCloseModal.bind(this, 'Project')}
                />
            )}
            {modals.Tandm && (
                <TAndMModal
                    tickets={modals.Tandm.tickets}
                    handleConversions={handleConversions}
                    handleClose={handleCloseModal.bind(this, 'Tandm')}
                />
            )}
            {modals.NoCharge && (
                <NoChargeModal
                    tickets={modals.NoCharge.tickets}
                    handleConversions={handleConversions}
                    handleClose={handleCloseModal.bind(this, 'NoCharge')}
                />
            )}
            {modals.Detail && (
                <DetailModal
                    workOrder={modals.Detail}
                    handleClose={handleCloseModal.bind(this, 'Detail')}
                    handleTandM={handleOpenModal.bind(this, 'Tandm', {
                        companies: modals.Detail?.company?.id,
                        tickets: [modals.Detail]
                    })}
                    handleAgreement={handleOpenModal.bind(this, 'Agreement', {
                        companies: modals.Detail?.company?.id,
                        tickets: [modals.Detail]
                    })}
                    handleProject={handleOpenModal.bind(this, 'Project', {
                        companies: modals.Detail?.company?.id,
                        tickets: [modals.Detail]
                    })}
                    handleNoCharge={handleOpenModal.bind(this, 'NoCharge', {
                        companies: modals.Detail?.company?.id,
                        tickets: [modals.Detail]
                    })}
                />
            )}
        </div>
    );
};

const formatDateDifference = (date) => {
    if (!date) return;

    const createdDate = new Date(date);

    if (!createdDate) return;

    const diff = new Date().getTime() - createdDate.getTime();

    if (!diff) return;

    return `${Math.floor(diff / (1000 * 60 * 60 * 24))} days`;
};

export default ViewWorkOrder;
