import { tryParseDate, tryParseJSON } from '../../../js/services/manipulation';
import { isNullOrEmpty } from '../../../js/services/validation';
import DatePicker from '../input/DatePicker';
import { DateTime } from 'luxon';

import styles from '../../../styles/general/forminputs/FormDate.module.scss';

const FormDate = ({
    error,
    id,
    isEditable,
    onChange,
    styles: style,
    value,
    hideIcon = true
}) => {
    const jsDate = !isNullOrEmpty(value) ? tryParseDate(value) : null;
    return isEditable ? (
        <div
            style={{
                height: '100%',
                width: '100%',
                backgroundColor: error
                    ? 'rgba(255, 144, 144, 0.5)'
                    : 'rgba(255, 250, 155, 0.5)',
                display: 'flex',
                alignItems: 'center',
                ...tryParseJSON(style)
            }}
        >
            <DatePicker
                props={{
                    className: [styles.datePicker, 'lowPadding'].join(' '),
                    maxDate: DateTime.now().plus({ years: 1 }).toJSDate()
                }}
                value={jsDate}
                onChange={onChange}
                options={{
                    hideCalendarIcon: hideIcon
                }}
            />
        </div>
    ) : (
        <div
            style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                fontSize: '.8rem',
                marginLeft: '6px',
                marginTop: '1px',
                color: 'var(--light-text-color)'
            }}
        >
            <p style={tryParseJSON(style) ?? {}}>
                {value &&
                    new Intl.DateTimeFormat('en-US', {
                        timeZone: 'UTC',
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    }).format(new Date(value))}
            </p>
        </div>
    );
};

export default FormDate;
