import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import { DateTimePicker } from '../../../general/input/DatePicker';
import Dropdown from '../../../general/input/Dropdown';
import InputLabel from '../../../general/input/InputLabel';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/workorder/modal/NewWorkOrderTimeModal.module.scss';

const NewWorkOrderTimeModal = ({ handleClose, handleNew }) => {
    const submitButtonRef = useRef();

    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            notes: '',
            workType: [],
            hoursWorked: '',
            startDate: null
        }
    });

    const [{ loading: loadingWorkTypes, data: workTypes }] = useApi(
        '/connectwise/work-type',
        'GET'
    );

    const handleCreate = (data) => {
        handleNew(data);
        handleClose();
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>New Time Entry</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={(e) => {
                            handleSubmit(handleCreate)(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name={'workType'}
                                control={control}
                                rules={{
                                    required: 'You must supply a work type.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Work Type"
                                        required
                                        error={error?.message}
                                    >
                                        <Dropdown
                                            selected={value}
                                            handleSelect={onChange}
                                            options={workTypes?.data?.map?.(
                                                (workType) => ({
                                                    ...workType,
                                                    key: workType.id,
                                                    value: workType.name
                                                })
                                            )}
                                            placeholder="Work Type"
                                            loading={loadingWorkTypes}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name={'startDate'}
                                control={control}
                                rules={{
                                    required: 'You must supply a start date.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Start Date/Time"
                                        required
                                        error={error?.message}
                                        labelClick={false}
                                    >
                                        <DateTimePicker
                                            value={value}
                                            onChange={onChange}
                                            isClearable
                                            placeholderText="Start Date/Time"
                                            wrapperClassName={
                                                styles.dateWrapper
                                            }
                                            maxDate={new Date()}
                                        />
                                    </InputLabel>
                                )}
                            />
                            <div className={styles.hoursWorked}>
                                <Controller
                                    name="hoursWorked"
                                    control={control}
                                    rules={{
                                        required:
                                            'You must supply hours worked.',
                                        validate: (value) =>
                                            parseInt(value) > 24
                                                ? 'Hours cannot surpass 24.'
                                                : parseInt(value) < 0
                                                ? 'Hours cannot be negative'
                                                : null
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="Hours Worked"
                                            required
                                            error={error?.message}
                                            labelClick={false}
                                        >
                                            <TextInput
                                                value={value}
                                                onChange={(e) => {
                                                    if (!isNaN(e.target.value))
                                                        onChange(e);
                                                }}
                                                placeholder="Hours"
                                                onEnter={handleSubmitClicked}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name={'notes'}
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Notes"
                                        error={error?.message}
                                    >
                                        <TextInput
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Notes"
                                            lines={4}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Create" onClick={handleSubmitClicked} />
            </Modal.Actions>
        </Modal>
    );
};

export default NewWorkOrderTimeModal;
