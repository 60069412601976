import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/general/input/TextInput.module.scss';

type TextInputProps = {
    autoComplete?: string;
    expand?: boolean;
    placeholder?: string;
    value: string;
    onChange: any;
    onEnter?: any;
    fontSize?: string;
    lines?: number;
    readonly?: boolean;
    inputRef?: any;
    icon?: any;
    classes?: {
        input?: any;
        container?: any;
    };
    style?: any;
    autoFocus?: boolean;
    name?: string;
    disabled?: boolean;
    validate?: string;
    tabIndex?: number;
    handlePaste?: any;
};

const TextInput = ({
    autoComplete,
    expand,
    placeholder,
    value,
    onChange = () => null,
    onEnter,
    fontSize = '13px',
    lines = 1,
    icon,
    readonly = false,
    inputRef,
    classes,
    style = {},
    autoFocus = false,
    name,
    disabled,
    validate,
    tabIndex,
    handlePaste
}: TextInputProps) => {
    const minHeight = useRef(0);
    const innerInputRef = useRef<any>(null);

    useEffect(() => {
        if (!expand) return;
        minHeight.current = innerInputRef?.current?.clientHeight;
        setTimeout(() => {
            minHeight.current = innerInputRef?.current?.clientHeight;
            innerInputRef.current.style.height = 0;
            innerInputRef.current.style.height = `${
                minHeight.current >= innerInputRef.current.scrollHeight
                    ? minHeight.current
                    : innerInputRef.current.scrollHeight
            }px`;
        }, 100);
    }, [expand]);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            if (onEnter) onEnter?.(e);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleChange = (e: any) => {
        if(validate) {
            switch(validate){
                case 'numeric':
                    if(!/^[0-9]*$/gi.test(e.target.value)){
                        return;
                    }
                    break;
                case 'decimal':
                    if(!/^[0-9]*(\.[0-9]?[0-9]?)?$/gi.test(e.target.value)){
                        return;
                    }
                    break;
                case 'alphabetic':
                    if(!/^([a-z]|[A-Z])*$/gi.test(e.target.value)){
                        return;
                    }
                    break;
            }
        }
        if (expand) e.target.style.height = 0;

        onChange?.(e);

        if (expand)
            e.target.style.height = `${
                minHeight.current >= e.target.scrollHeight
                    ? minHeight.current
                    : e.target.scrollHeight
            }px`;
    };

    return (
        <div
            className={[
                styles.container,
                icon ? styles.iconInput : null,
                classes?.container
            ].join(' ')}
            style={{ fontSize: fontSize, ...style.container }}
        >
            {lines > 1 || expand ? (
                <textarea
                    value={value}
                    rows={lines}
                    onChange={readonly ? undefined : handleChange}
                    onPaste={handlePaste}
                    placeholder={placeholder}
                    ref={inputRef ?? innerInputRef}
                    className={[
                        styles.input,
                        readonly ? styles?.readonly : null,
                        classes?.input
                    ].join(' ')}
                    readOnly={readonly}
                    autoFocus={autoFocus}
                    style={{
                        ...style.input
                    }}
                    autoComplete={autoComplete}
                    name={name}
                ></textarea>
            ) : (
                <React.Fragment>
                    {icon && (
                        <span className={styles.icon} style={{ ...style.icon }}>
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    )}
                    <input
                        type="text"
                        value={value}
                        onChange={readonly ? undefined : handleChange}
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        className={[
                            styles.input,
                            readonly ? styles?.readonly : null,
                            classes?.input
                        ].join(' ')}
                        ref={inputRef}
                        readOnly={readonly}
                        style={{ ...style.input }}
                        autoComplete={autoComplete}
                        name={name}
                        disabled={disabled}
                        tabIndex={tabIndex}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default TextInput;
