import useUser from '../../../hooks/useUser';

import { tryParseJSON } from '../../../js/services/manipulation';
import TextInput from '../input/TextInput';
import { TextTooltip } from '../Tooltip';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from '../../../styles/general/forminputs/FormTextInput.module.scss';

const FormTextInput = ({
    error,
    id,
    isEditable,
    onChange,
    props,
    styles,
    value
}) => {
    const rest = tryParseJSON(props ?? '{}');
    const { displayName } = useUser();

    const handleAutoFill = () => onChange(displayName);

    return isEditable ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative'
            }}
        >
            <TextInput
                value={value ?? ''}
                onChange={onChange}
                placeholder={rest.placeholder}
                expand={rest.expand ?? true}
                lines={rest.lines ?? 1}
                style={{
                    container: {
                        height: '100%',
                        ...(rest?.styles?.container ?? {})
                    },
                    input: {
                        height: '100%',
                        borderRadius: 0,
                        outline: 'none',
                        border: 'none',
                        background: error
                            ? 'rgba(255, 144, 144, 0.5)'
                            : 'rgba(165, 244, 165, 0.5)',
                        minHeight: rest.minHeight ?? '100%',
                        padding: rest.padding
                    }
                }}
            />
            {rest.autoFillUser && (
                <div className={css.removeLine} onClick={handleAutoFill}>
                    <TextTooltip
                        tooltip="Autofill"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            className={css.removeIcon}
                        />
                    </TextTooltip>
                </div>
            )}
        </div>
    ) : (
        <div
            style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                fontSize: '.8rem',
                marginLeft: '6px',
                marginTop: '1px',
                minHeight: rest.minHeight ?? '14px',
                color: 'var(--light-text-color)',
                wordBreak: 'break-word',
                padding: rest.padding ?? '4px 0',
                ...(rest.style ?? {}),
                ...styles
            }}
        >
            <p>{value}</p>
        </div>
    );
};

export default FormTextInput;
