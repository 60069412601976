import { useState } from 'react';

import SignatureModal from '../modal/SignatureModal';
import { tryParseJSON } from '@/js/services/manipulation';

const FormSignature = ({
    error,
    isEditable,
    onChange,
    props,
    style,
    value: defaultValue
}) => {
    const {
        height,
        signatureOnly,
        stringValue = true
    } = JSON.parse(props ?? '{}');
    const header = '';
    const value = typeof defaultValue === 'string' && !signatureOnly ? tryParseJSON(defaultValue) : defaultValue;

    const [modal, setModal] = useState(false);

    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => {
        setModal(false);
    };

    const handleSave = (signature) => {
        onChange(
            signatureOnly
                ? signature
                : stringValue
                ? JSON.stringify({
                      signature: signature,
                      label: header
                  })
                : {
                      signature: signature,
                      label: header
                  }
        );
        setModal(false);
    };

    return isEditable ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div
                style={{
                    background: error
                        ? 'rgba(255, 144, 144, 0.5)'
                        : 'rgba(211, 211, 211, 0.5)',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    ...JSON.parse(style ?? '{}')
                }}
                onClick={handleModalOpen}
            >
                {(value?.signature || (signatureOnly && value)) && (
                    <img
                        srcSet={signatureOnly ? value : value?.signature}
                        alt=""
                        style={{
                            width: '100%',
                            height: height ?? '64px',
                            objectFit: 'scale-down'
                        }}
                    />
                )}
            </div>
            {modal && (
                <SignatureModal
                    handleClose={handleModalClose}
                    handleSave={handleSave}
                    header={header}
                />
            )}
        </div>
    ) : (
        <div
            style={{
                width: '100%'
            }}
        >
            {(value?.signature || (signatureOnly && value)) && (
                <img
                    srcSet={signatureOnly ? value : value?.signature}
                    alt=""
                    style={{
                        width: '100%',
                        height: height ?? '64px',
                        objectFit: 'scale-down'
                    }}
                />
            )}
        </div>
    );
};

export default FormSignature;
