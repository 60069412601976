import { useRef, useState } from 'react';

import NewTerritoryAssignmentModal from '../modal/NewTerritoryAssignmentModal';
import ActionCell from '@/components/general/grid/cell renderers/ActionCell';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import TextCell from '@general/grid/cell renderers/TextCell';
import Grid from '@general/grid/Grid';
import DeleteTerritoryAssignmentModal from '../modal/DeleteTerritoryAssignmentModal';

const TerritoryAssignmentGrid = () => {
    const gridRef = useRef();

    const [modals, setModals] = useState({
        New: false,
        Delete: false
    });
    const [selected, setSelected] = useState([]);

    const handleRowSelection = (selection) => setSelected(selection);

    const handleOpenModal = (option, value) => {
        setModals((modal) => ({
            ...modal,
            [option]: value ?? true
        }));
    };

    const handleCloseModal = (option) => {
        setModals((modal) => ({
            ...modal,
            [option]: false
        }));
    };

    const handleNew = (records) =>
        records.forEach((record) => gridRef.current.addRow(record));

    const handleDelete = (records) =>
        records.forEach((record) => gridRef.current.removeRow(record));

    return (
        <div className="w-full h-full">
            <Grid
                ref={gridRef}
                multisort={false}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleOpenModal.bind(this, 'New')
                    },
                    {
                        type: 'secondary',
                        label: 'Delete',
                        icon: faTrashAlt,
                        disabled: selected.length <= 0,
                        onClick: handleOpenModal.bind(this, 'Delete', selected)
                    }
                ]}
                fixed
                multiselect
                rowSelect
                checkboxSelection
                handleRowSelection={handleRowSelection}
                selected={selected}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/connectwise/territory/assignments`,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Email',
                        key: 'email',
                        dataKey: 'email',
                        width: 200,
                        minWidth: 200,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Territory',
                        key: 'territory',
                        dataKey: 'territoryName',
                        width: 300,
                        minWidth: 300,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 50,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    {
                                        icon: faTrashAlt,
                                        tooltip: {
                                            tooltip: 'Delete',
                                            hoverDelay: 650,
                                            hoverTrigger: 'always'
                                        },
                                        type: 'grayscale',
                                        onClick: handleOpenModal.bind(
                                            this,
                                            'Delete',
                                            [rowData]
                                        )
                                    }
                                ]}
                            />
                        )
                    }
                ]}
            />
            {modals.New && (
                <NewTerritoryAssignmentModal
                    handleClose={handleCloseModal.bind(this, 'New')}
                    handleNew={handleNew}
                />
            )}
            {modals.Delete && (
                <DeleteTerritoryAssignmentModal
                    territoryAssignments={modals.Delete}
                    handleClose={handleCloseModal.bind(this, 'Delete')}
                    handleDelete={handleDelete}
                />
            )}
        </div>
    );
};

export default TerritoryAssignmentGrid;
