import ErrorMessage from '../ErrorMessage';

import styles from '../../../styles/general/input/InputLabel.module.scss';

type InputLabelProps = {
    children: any;
    required?: boolean;
    label?: string;
    labelClick?: boolean;
    handleSubmit?: any;
    disclaimer?: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
    error?: string;
    style?: any;
    classes?: any;
    tab?: boolean;
    variant?: string;
};

const InputLabel = ({
    children,
    required,
    label,
    labelClick = true,
    handleSubmit,
    disclaimer,
    position = 'top',
    error,
    style = {},
    classes = {},
    tab = false,
    variant = ''
}: InputLabelProps) => {
    const handleKeydown = (e: any) => {
        if (e.key === 'Enter') {
            handleSubmit?.();
        }
    };

    return (
        <div
            className={[
                styles.container,
                styles[position?.toLowerCase?.()],
                classes?.container,
                styles[variant]
            ].join(' ')}
            style={{ display: 'flex', ...(style.container ?? {}) }}
        >
            <LabelContent
                labelClick={labelClick}
                position={position}
                style={style}
                tab={tab}
                handleKeydown={handleKeydown}
            >
                {(label || required) && (
                    <div
                        className={[
                            styles.inputLabel,
                            classes.label,
                            styles[variant]
                        ].join(' ')}
                        style={{ ...(style.label ?? {}) }}
                    >
                        {required && (
                            <div
                                className={[
                                    styles.requiredIndicator,
                                    classes?.requiredIndicator
                                ].join(' ')}
                            >
                                *
                            </div>
                        )}
                        {label && (
                            <div
                                className={styles.label}
                                style={{ ...(style.label ?? {}) }}
                            >
                                {label}
                            </div>
                        )}
                    </div>
                )}
                <div
                    style={style?.input}
                    className={[
                        styles.inputContent,
                        error ? styles.error : null,
                        classes?.content,
                        styles[variant]
                    ].join(' ')}
                >
                    {children}
                </div>
            </LabelContent>
            {error && <ErrorMessage error={error} />}
            {disclaimer && (
                <div className={styles.disclaimer}>{disclaimer}</div>
            )}
        </div>
    );
};

type LabelContentProps = Pick<
    InputLabelProps,
    'children' | 'labelClick' | 'position' | 'style' | 'tab'
> & {
    handleKeydown: any;
};

const LabelContent = ({
    children,
    labelClick,
    position,
    style,
    tab,
    handleKeydown
}: LabelContentProps) =>
    labelClick ? (
        <label
            className={styles[position?.toLowerCase?.() ?? '']}
            style={style?.content}
            tabIndex={tab ? 0 : undefined}
            onKeyDown={handleKeydown}
        >
            {children}
        </label>
    ) : (
        <div
            className={[
                styles[position?.toLowerCase?.() ?? ''],
                styles.label
            ].join(' ')}
            style={style?.content}
            tabIndex={tab ? 0 : undefined}
            onKeyDown={handleKeydown}
        >
            {children}
        </div>
    );

export default InputLabel;
